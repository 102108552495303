import React from 'react'
import User from '../components/FamilyInfo/User'

export default function UserInfo() {
  return (
    <div>
      <User/>
    </div>
  )
}
